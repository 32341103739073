import {HousesStore, NetworkStore} from './stores';

// const BASE_URL = 'https://dev.ptruck.de';
// const BASE_URL = 'http://localhost:3000';
// const BASE_URL = 'http://10.0.2.2:3000';
// const BASE_URL = 'http://192.168.0.243:3000';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const LIST_HOUSES_QUERY = gql`
  query listHouses($ownHouses: Boolean) {
    houses(ownHouses: $ownHouses) {
      id
      name
      freeSpots
      bookableSpots
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      error
      errorcode
      user {
        username
      }
      apiKey {
        token
      }
    }
  }
`;

//
// const axiosInstance = axios.create({
//   // baseURL: BASE_URL,
//   timeout: 7000,
//   responseType: 'json',
//   headers: {
//     'Content-Encoding': 'gzip',
//   },
// });
//
// axiosInstance.interceptors.response.use(
//   (response) => {
//     if (response.data.errorcode !== 0) {
//       throw new Error(response.data.error);
//     }
//
//     return response;
//   },
//   (error) => Promise.reject(error),
// );
//
// export const listHouses = async (listOwnHouses = false) => {
//   try {
//     const response = await axiosInstance.get('/api/houses', {
//       params: {listOwnHouses},
//     });
//
//     if (response.status === 200) {
//       HousesStore.update((s) => {
//         s.houses = response.data.houses.filter(
//           (house) => house.name.indexOf('Demo') === -1,
//         );
//       });
//     } else {
//       NetworkStore.update((s) => {
//         s.error = response.data;
//       });
//     }
//   } catch (err) {
//     NetworkStore.update((s) => {
//       s.error = err.message;
//     });
//   }
// };
//
// export const getNearParkingSpots = async (lat, lon) => {
//   try {
//     const response = await axiosInstance.get('/api/houses', {
//       params: {lat, lon},
//     });
//
//     if (response.status === 200) {
//       HousesStore.update((s) => {
//         s.houses = response.data.houses.filter(
//           (house) => house.name.indexOf('Demo') === -1,
//         );
//       });
//     } else {
//       NetworkStore.update((s) => {
//         s.error = response.data;
//       });
//     }
//   } catch (err) {
//     NetworkStore.update((s) => {
//       s.error = err.message;
//     });
//   }
// };
